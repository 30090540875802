import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-demo",
  "seoTitle": "Boka demo",
  "seoDescr": "-",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "title": "Boka en demo",
  "text": "Boka demo 45 min så genomför vi den via Teams webmöte eller fysiskt möte där vi ger en presentation av Zimply samt går igenom era utmaningar, behov och frågor. Ange önskad tid samt datum så återkommer vi med en kalenderinbjudan så fort vi kan.",
  "url": "https://calendly.com/zimply/demo-med-zimply"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      